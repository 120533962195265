// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-failure-page-js": () => import("../src/templates/failure-page.js" /* webpackChunkName: "component---src-templates-failure-page-js" */),
  "component---src-templates-index-page-js": () => import("../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-success-page-js": () => import("../src/templates/success-page.js" /* webpackChunkName: "component---src-templates-success-page-js" */),
  "component---src-templates-game-template-js": () => import("../src/templates/game-template.js" /* webpackChunkName: "component---src-templates-game-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

